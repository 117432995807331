import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Container, Col, Row, Card, CardBody, CardHeader } from "reactstrap";
import SLDPPlayer from "./usersldp";

function Overview() {
  document.title = "User Dashboard Details | Veriyum - Admin & Dashboard";
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const playerRef = useRef(null);

  const [service, setService] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [setError] = useState(null);
  const [server_id, setServer_id] = useState("");
  const [client_id, setClient_id] = useState("");
  const [api_key, setApi_key] = useState("");
  const [salt, setSalt] = useState("");
  const [hash, setHash] = useState("");
  const [checkStatus, setCheckStatus] = useState([]);
  const [playerUrl, setPlayerUrl] = useState("");
  const [userId, setUserId] = useState("");
  const [playerKey, setPlayerKey] = useState(0);
  const [authState, setAuthState] = useState({
    id: "",
    userId: "",
  });

  useEffect(() => {
    axios
      .get("http://195.87.127.148:3001/auth/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            id: response.id,
            userId: response.id,
          });
          setUserId(response.id);
        }
      });
  }, []);

  useEffect(() => {
    setPlayerKey((prevKey) => prevKey + 1);

    const currentPlayerRef = playerRef.current;
    return () => {
      if (currentPlayerRef && currentPlayerRef.destroy) {
        currentPlayerRef.destroy();
      }
    };
  }, [location]);

  useEffect(() => {
    const storedData = localStorage.getItem("playerUrl");

    if (storedData) {
      try {
        const trimmedData = storedData.trim();
        const parsedData = JSON.parse(trimmedData);
        if (parsedData.server_id === server_id) {
          setPlayerUrl(parsedData.playerUrl);
        } else {
          setPlayerUrl("");
        }
      } catch (error) {
        console.error("Error parsing stored data:", error);
        setPlayerUrl("");
      }
    }
  }, [server_id]);
  const fetchServerInfo = () => {
    const currentServer = localStorage.getItem("currentServer");
    if (currentServer) {
      const serverInfo = JSON.parse(currentServer);
      setServer_id(serverInfo.server_id);
      setClient_id(serverInfo.client_id);
      setApi_key(serverInfo.api_key);
      setSalt(serverInfo.salt);
      setHash(serverInfo.hash);
    }
  };

  useEffect(() => {
    const serverStatus = async () => {
      await fetchServerInfo();
      await nimbleStatus();
    };
    serverStatus();
    const intervalId = setInterval(serverStatus, 10000);

    return () => clearInterval(intervalId);
  }, [userId]);

  useEffect(() => {
    if (server_id && client_id && api_key) {
      axios
        .get(`http://195.87.127.148:3001/streams/${id}`)
        .then((res) => {
          setService(res);
        })
        .catch((error) => {
          console.error(error);
          setError(error.message);
        })
        .finally(() => {
          setIsLoaded(true);
        });
    }
  }, [id, navigate, server_id, client_id, api_key]);

  const nimbleStatus = async () => {
    try {
      const response = await axios.get(
        `http://${playerUrl}:8082/manage/live_streams_status?salt=${salt}&hash=${hash}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setCheckStatus(response || []);
    } catch (error) {
      setCheckStatus([]);
    }
  };

  return (
    <React.Fragment>
      <Container className="page-content">
        <Row>
          <Col xs={12} lg={12} className="mb-4">
            <Card>
              <CardHeader className="bg-dark text-white text-center text-capitalize">
                <h5 className="m-0">
                  {service.stream} / {service.application}
                </h5>
              </CardHeader>
              <CardBody>
                <div className="text-center mb-3">
                  {checkStatus.some(
                    (appData) =>
                      appData.app === service.application &&
                      appData.streams.some(
                        (stream) => stream.strm === service.stream
                      )
                  ) ? (
                    <>
                      <i className="fa fa-circle text-success p-1">
                        <span className=""> </span>{" "}
                      </i>
                      <span className="text-end">Stream Status:</span>
                      <span className=" text-white text-capitalize">
                        {" "}
                        Online
                      </span>
                    </>
                  ) : (
                    <>
                      <i className="fa fa-circle text-danger p-1"></i>
                      <span className="ml-2">Stream Status:</span>
                      <span className="ml-3 text-white text-capitalize">
                        {" "}
                        Offline
                      </span>
                    </>
                  )}
                </div>
                <div className="player-wrapper d-flex justify-content-center">
                  {isLoaded && (
                    <div className="embed-responsive embed-responsive-16by9">
                      <SLDPPlayer
                        key={playerKey}
                        streamUrl={`${playerUrl}:8081/${service.application}/${service.stream}`}
                        autoPlay
                        height={window.innerWidth <= 768 ? "auto" : 720}
                        width={window.innerWidth <= 768 ? "auto" : 1280}
                        ref={playerRef}
                      />
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Overview;
